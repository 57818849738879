<template>
  <div class="meldung" >
    <transition name="slide-x-transition" >
    <div v-if="doubletten.length > 0" >
    <v-icon color="blue" small>mdi-information</v-icon>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <small v-bind="attrs" v-on="on"><span class="text-decoration-underline">{{ doubletten.length }} {{doubletten.length == 1 ? 'Firma' : 'Firmen'}}</span> mit gleichen Namen</small>
        </template>
        <span v-html="displayedDoubletten"></span>
      </v-tooltip>
    </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: ['items', 'input'],
  name: "check-name",
  data() {
    return {
      lock: false,
    }
  },

  computed: {
    doubletten() {
      if(this.input == null || this.input === ''){
        return [];
      }
    return this.items.filter(i => i.name.toLowerCase().indexOf(this.input.toLowerCase()) > -1);
    },

    displayedDoubletten(){
      if(this.input == null || this.input === ''){
        return [];
      }
      const showedFirmen = 15;
      const filteredFirmen = this.items.filter(i => i.name.toLowerCase().indexOf(this.input.toLowerCase()) > -1);
      const displayedfirmen = filteredFirmen.slice(0, showedFirmen);

      const firmenInfos = displayedfirmen.map((firma) => {
        return `${firma.name} ${firma.straße} ${firma.plz} ${firma.ort}`
      }).join("<br/>");

        return `${firmenInfos} ${filteredFirmen.length > showedFirmen ? '<br/>... + ' + (filteredFirmen.length - showedFirmen) : '' }`
    }
  },
  methods: {}
}

</script>

<style scoped>
.meldung { height: 24px;}
</style>
